import clsx from 'clsx'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {constants} from '../../../../utils/const'
import { HeaderUserMenu} from '../../../partials'
import HeaderNotificationsMenu from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import { getNotifications } from './_requests'
import { useEffect, useState } from 'react'
import { relative } from 'path'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const redDotClass = 'red-dot'
const Navbar = () => {
  const userDetail = useSelector((user: any) => user?.authDetail?.data)

  const [data, setData] = useState<any>([])

  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    // fetchData()
  }, [])

  const redDotBlink = !data?.every((notification:any) => notification?.read === 1);

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
          >
          <i className={`fa fa-bell fa-2x ${btnIconClass}`} aria-hidden="true" style={{position: 'relative'}}>

          {redDotBlink && <span className={redDotClass}></span>}
          </i>
        
        </div>
        <HeaderNotificationsMenu showRedDot={fetchData}/> 
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {userDetail?.branch_name ? userDetail?.branch_name : constants.PROJECT_NAME}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
